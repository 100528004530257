import request from '@/utils/http';
// 获取门店信息
export function getShopList_api(data) {
    return request({
        url: '/v12/user/shop',
        method: 'get',
        params: data
    })
}

// 获取399订单
export function getConversionOrder_api(data) {
    return request({
        url: '/v12/user/userConversionOrder',
        method: 'get',
        params: data
    })
}
// 获取399订单兑换时长
export function getOrderTime_api(data) {
    return request({
        url: '/v12/user/UserConversionOrder/update',
        method: 'put',
        data
    })
}
// 兑换时长
export function exchangeOrder_api(data) {
    return request({
        url: '/v12/user/userConversionOrder',
        method: 'post',
        data
    })
}
// 兑换明细
export function getOrderRead_api(data) {
    return request({
        url: '/v12/user/UserConversionOrder/read',
        method: 'get',
        params: data
    })
}

// 获取用户信息=================================================
export function getUserData_api() {
    return request({
        url: '/v12/user/User/read',
        method: 'get',
    })
}
// 修改用户信息（电子证件
export function changeUserData_api(data) {
    return request({
        url: '/v12/user/User/update',
        method: 'put',
        data
    })
}
// 修改用户信息
export function changeUserInfo_api(data) {
    return request({
        url: '/v12/user/User/updateInfo',
        method: 'post',
        data
    })
}

// 用户注销
export function deleteUser_api() {
    return request({
        url: '/v12/user/user/1',
        method: 'delete',
    })
}